/* Estilos e animações */

.cards-container {
  margin-top: -80px;
  margin-bottom: -80px;
}

.sessao-mg {
  margin-top: 80px;
  margin-bottom: 80px;
}

.banner img {
  width: 100%;
  height: auto;
}

/* Estilos da seção de serviços */
.services-section {
  display: flex;
  align-items: center;
  justify-content: center;
}

.services-section h6 {
  font-family: 'Arial, sans-serif';
}

.services-section h2 {
  font-weight: bold;
}

.services-section .text-left {
  padding-right: 40px;
}

/* Cards estilizados com bordas arredondadas */
.custom-card {
  background: rgba(230, 52, 26, 0.48) !important;
  border-radius: 16px !important;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1) !important;
  backdrop-filter: blur(5px) !important;
  -webkit-backdrop-filter: blur(5px) !important;
  border: 1px solid rgba(230, 52, 26, 0.3) !important;
}

.custom-card .card-title {
  font-size: 18px !important;
  color: black !important;
  white-space: normal !important;
  word-wrap: break-word !important;
}


.services-background {
  display: flex;
  align-items: center;
  justify-content: center;
  background: url('./../img/fundoSessao1.jpg') no-repeat center center / cover, rgba(255, 255, 255, 0.5);
  background-size: cover;
  background-position: center -70px;
  padding: 60px 0;
}

@media (max-width: 997px) {
  .service-card {
    flex: 0 0 100%;
    margin-bottom: 20px;
  }
}

@media (max-width: 1405px) {
  .navbar-nav .nav-link {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-size: 14px;
  }

  .navbar-nav .nav-link .fa {
    margin-bottom: 4px;
    font-size: 16px;
  }
}

.bg-black .d-flex {
  flex-direction: row;
  align-items: center;
}



.icon-circle {
  width: 60px;
  height: 60px;
  background-color: #E6341A;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

/* Animação ao aparecer */
.service-card {
  animation: fadeIn 1s ease-in-out;
}

.middle-card {
  margin-top: -20px;
}

/* Animação ao aparecer */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}